@charset "utf-8";

$text: hsl(0, 0%, 40%);
$text-strong: hsl(0, 0%, 40%);
$body-color: hsl(0, 0%, 40%);
$modal-content-width: 90%;
$fullhd-enabled: false;
$widescreen-enabled: false;

$primary: #003057;

$navbar-background-color: $primary;
$navbar-item-color: #FFF;
$navbar-item-hover-background-color: #FFF;
$navbar-item-hover-color: #003057;

@import "node_modules/bulma/bulma.sass";

.content table td {
    vertical-align: middle;
}
